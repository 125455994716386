import React from 'react'
import { Helmet } from 'react-helmet';

const NotFound = () => {
  return (
      <>
        <Helmet>
            <title>404: Not found</title>
        </Helmet>

        <h1>404: Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </>    
  )
}

export default NotFound;
